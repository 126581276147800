import { FC } from 'react';
import tw from 'twin.macro';
import { Centered, ContentSection, CTA, NoBreak } from '~/components';
import { Frontmatter } from '~/config';
import Checkmarks from '~/images/ctas/checkmarks.svg';
import Clipboard from '~/images/ctas/clipboard.svg';
import TalkBubble from '~/images/ctas/talk-bubble.svg';

// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: 'Library: CTA',
  order: 20,
  screenShoot: false,
};

const LibraryPage: FC = () => {
  return (
    <>
      <ContentSection>
        <Centered fullHeight>
          <div tw="flex flex-col px-4 gap-2 md:(flex-row h-36)">
            <CTA
              to="/guidelines/"
              bodyCss={tw`mb-14`}
              SVG={<Clipboard alt="guidelines" />}
            >
              Clinical guidelines and recommendations
            </CTA>
            <CTA
              to="/access-and-support/"
              bodyCss={tw`mb-14`}
              SVG={<TalkBubble alt="support" />}
            >
              Support and product access <NoBreak>for patients</NoBreak>
            </CTA>
            <CTA to="/resources/" SVG={<Checkmarks alt="resources" />}>
              Useful resources for your office <NoBreak>and patients</NoBreak>
            </CTA>
          </div>
        </Centered>
      </ContentSection>
    </>
  );
};

export default LibraryPage;
